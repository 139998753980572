import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorPage } from 'dd-client/site/error/components/ErrorPage';
import { LanguageCode } from 'dd-client/site/i18n/utils/languageCode';
import ServerErrorImage from './assets/500.png';
import ServerErrorVideo from './assets/500.webm';
import { Component } from './types';

const ServerErrorPage: Component = (): ReactElement => {
  const { t, i18n } = useTranslation();
  const de = i18n.getFixedT(LanguageCode.DE);

  const text = useMemo(
    () => ({
      internalServerError: t('Internal Server Error'),
      internalServerErrorDE: de('Internal Server Error'),
      ourServer: t('Our server is currently unable to process your request. Please try again later.'),
    }),
    [de, t],
  );

  return (
    <ErrorPage
      analytics={{
        title: text.internalServerErrorDE,
      }}
      errorCode={500}
      image={ServerErrorImage}
      subtitle={text.ourServer}
      title={text.internalServerError}
      video={ServerErrorVideo}
    />
  );
};

export {
  ServerErrorPage,
};
