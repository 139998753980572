import React, { ReactElement, useMemo } from 'react';
import { Accordion } from 'dd-client/site/common/components/Accordion';
import { ContentNavigation } from 'dd-client/site/common/components/ContentNavigation';
import { AccordionAndContentNavigationData } from 'dd-client/site/common/types';
import { Item } from './Item/Item';
import { Component, Props } from './types';
import './Accessories.scss';

const Accessories: Component = ({ accessories }: Props): ReactElement => {
  const accessoriesData = useMemo(
    (): AccordionAndContentNavigationData =>  accessories.map(
      accessoryGroup => ({
        content: accessoryGroup.products.map(
          product => (
            <Item
              isNew={product.isNew}
              isSale={product.isSale}
              key={product.sku}
              name={product.name}
              price={product.price}
              specialPrice={product.specialPrice}
              stock={product.stock}
              thumbnails={product.thumbnails}
              url={product.url}
            />
          ),
        ),
        header: `${accessoryGroup.label} (${accessoryGroup.total})`,
      }),
    ),
    [accessories],
  );

  return (
    <div className="Accessories">
      <Accordion
        className="Accessories-Accordion"
        data={accessoriesData}
        defaultOpenIndexArray={[0]}
      />
      <ContentNavigation
        className="Accessories-ContentNavigation"
        data={accessoriesData}
        defaultOpenIndex={0}
      />
    </div>
  );
};

export { Accessories };
