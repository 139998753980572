import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { datadogLogs } from '@datadog/browser-logs';
import classNames from 'classnames';
import { Head } from 'dd-client/site/app/components/App/Head';
import { Props as HeadProps } from 'dd-client/site/app/components/App/Head';
import { Loader } from 'dd-client/site/common/components/Loader';
import { useDevice } from 'dd-client/site/common/hooks/useDevice';
import { usePageMatch } from 'dd-client/site/common/hooks/usePageMatch';
import { usePrevious } from 'dd-client/site/common/hooks/usePrevious';
import { hasNews, hasPromotions } from 'dd-client/site/common/utils/features';
import { logger } from 'dd-client/site/common/utils/logger/logger';
import { isPastDeal } from 'dd-client/site/common/utils/pastDeal/pastDeal';
import { isServer } from 'dd-client/site/common/utils/server';
import { getConfig } from 'dd-client/site/config/utils/config';
import { Deal } from 'dd-client/site/deal/hooks/api/types';
import { useCurrentDeal } from 'dd-client/site/deal/hooks/useCurrentDeal';
import { NotFoundPage } from 'dd-client/site/error/components/NotFoundPage';
import { ServerErrorPage } from 'dd-client/site/error/components/ServerErrorPage';
import { Promotions } from 'dd-client/site/promotions/components/Promotions';
import { CategoryMenu } from './CategoryMenu';
import { Comments } from './Comments';
import { FullPageAnimation } from './FullPageAnimation';
import { News } from './News';
import { ProductDetails } from './ProductDetails';
import { ProductMain } from './ProductMain';
import { RecommendedProducts } from './RecommendedProducts';
import { Component } from './types';
import { UniqueSellingPoints } from './UniqueSellingPoints';
import './DealPage.scss';

// the values should be the same as the animation duration in css (last animated html element plus animation duration)
const MOBILE_DEAL_PAGE_ANIMATION_DURATION = 550;
const MOBILE_HOME_PAGE_ANIMATION_DURATION = 450;

const DealPage: Component = (): ReactElement | null => {
  const { isHomePage } = usePageMatch();
  const prevIsHomePage = usePrevious(isHomePage);
  const { isMobile } = useDevice();
  const currentDeal = useCurrentDeal();
  const currentDealData = currentDeal?.data;
  const { t, i18n: { language } } = useTranslation();
  const [isDealPageMobileAnimated, setIsDealPageMobileAnimated] = useState(false);

  const text = useMemo(
    () => ({
      from: t('from'),
      onlyNowDiscount: (calculatedDiscount: number) => t('Only now {calculatedDiscount}% discount!', {
        calculatedDiscount,
      }),
    }),
    [t],
  );

  useMemo(
    () => {
      if (isMobile) {
        if (
          !isHomePage && prevIsHomePage
          // when first enter the page (after reloading), the home page should not animate
          || isHomePage && prevIsHomePage === false
        ) {
          setIsDealPageMobileAnimated(true);

          setTimeout(
            // class 'DealPage--MobileAnimation' has to be removed from DOM after animation, there is an 'opacity' which change stacking context
            // and e.g. video modal is overlapped by other html elements
            // more info: https://stackoverflow.com/questions/16148007/which-css-properties-create-a-stacking-context,
            // https://stackoverflow.com/questions/50883309/how-come-css-animations-change-z-index
            () => setIsDealPageMobileAnimated(false),
            isHomePage
              ? MOBILE_HOME_PAGE_ANIMATION_DURATION
              : MOBILE_DEAL_PAGE_ANIMATION_DURATION,
          );
        }
      } else {
        setIsDealPageMobileAnimated(false);
      }
    },
    [isHomePage, isMobile, prevIsHomePage],
  );

  const rootClassName = classNames(
    'DealPage',
    {
      'DealPage--HomePage': isHomePage,
      'DealPage--MobileAnimation': isDealPageMobileAnimated,
    },
  );

  const getHeadProps = useCallback(
    (currentDealData: Deal): HeadProps => {
      const { title, subtitle, keyFeatures, meta, og, calculatedDiscount, slug, endDate } = currentDealData;

      return {
        analytics: {
          title: isPastDeal(endDate)
            ? `${currentDealData.analytics.dealTitle} ${currentDealData.analytics.dealSubtitle}`
            : currentDealData.analytics.metaTitle,
        },
        description: isPastDeal(endDate)
          ? `${title} ${subtitle}: ${
            keyFeatures.map(
              feature => feature.keyFeature,
            ).join('. ')
          }.`
          : meta.description,

        ogTags: {
          description: og.description || `${title} ${text.from} ${subtitle}`,
          image: og.image,
          title: og.title || text.onlyNowDiscount(calculatedDiscount || 0),
          type: 'product',
          url: `${getConfig('url.root')}/${language}/deal/${slug}`,
        },

        title: isPastDeal(endDate)
          ? `${title} ${subtitle}`
          : meta.title,
      };
    },
    [language, text],
  );

  if (currentDeal?.isError) {
    let errorObj = null;

    try {
      errorObj = currentDeal.error && JSON.parse(currentDeal.error);
    } catch (e) {
      logger.error('JSON.parse error in DealPage', { error: e });
    }
    const statusCode = errorObj?.response?.status;

    if (!isServer && statusCode !== 404) {
      datadogLogs.logger.error('Deal Page error', {
        error: { ...errorObj, service: 'browser_daydeal' },
      });
    }

    return statusCode === 404
      ? <NotFoundPage />
      : <ServerErrorPage />;
  }

  return (
    <div className={rootClassName}>

      <FullPageAnimation />

      {!currentDeal || currentDeal.isLoading ? (
        <Loader className="DealPage-Loader" />
      ) : (
        <CategoryMenu />
      )}

      {currentDealData && (
        <>
          <Head {...getHeadProps(currentDealData)} />

          <ProductMain
            className="DealPage-ProductMain"
            dealData={currentDealData}
            isHomePage={isHomePage}
          />

          <CategoryMenu
            isForMobilesOnHomePage
          />

          <ProductDetails
            key={currentDealData.id}
            className="DealPage-ProductDetails"
            apps={currentDealData.apps}
            accessories={currentDealData.accessories}
            description={currentDealData.description}
            detail={currentDealData.detail}
            links={currentDealData.links}
            media={currentDealData.media}
            scopeOfDelivery={currentDealData.scopeOfDelivery}
            specification={currentDealData.specification}
          />

          <Comments
            className='DealPage-Comments'
            dealId={currentDealData.id}
          />

          <RecommendedProducts
            className='DealPage-RecommendedProducts'
            recommendations={currentDealData.recommendations}
          />

          <CategoryMenu
            isForMobilesOnDealPage
            /* reset after change the deal/url */
            key={`categoryMenu-${currentDealData.id}`}
          />

          {hasNews && (
            <News
              className='DealPage-News'
              categoryId={currentDealData.category.id}
            />
          )}

          <UniqueSellingPoints
            className='DealPage-UniqueSellingPoints'
          />

          {hasPromotions && (
            <Promotions categoryId={currentDealData.category.id} />
          )}
        </>
      )}
    </div>
  );
};

export {
  DealPage,
};
