import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Alert } from 'dd-client/site/common/components/Alert';
import { Button } from 'dd-client/site/common/components/Button';
import { NewsletterDropdown } from 'dd-client/site/common/components/Form/NewsletterDropdown';
import { TextInput } from 'dd-client/site/common/components/Form/TextInput';
import { useSubmitNewsletter } from 'dd-client/site/common/hooks/useSubmitNewsletter';
import { logger } from 'dd-client/site/common/utils/logger/logger';
import { getFormFieldCheckboxes, getSelectedCategories } from 'dd-client/site/common/utils/newsletter/newsletter';
import { EMAIL_PATTERN, emailRule } from 'dd-client/site/common/utils/validations/validationRules';
import { LanguageCode } from 'dd-client/site/i18n/utils/languageCode';
import { Component, NewsletterFormData, Props } from './types';
import './Newsletter.scss';

const FormField = {
  email: 'email',
};
const ALERT_TIMEOUT = 3000;

const Newsletter: Component = ({
  className,
  hasProcessInformation = true,
}: Props): ReactElement => {
  const { t, i18n } = useTranslation();
  const language = i18n.language as LanguageCode;
  const {
    handleSubmit,
    formState: { errors },
    control,
    setError,
    reset,
    clearErrors,
  } = useForm();
  const submitNewsletter = useSubmitNewsletter();

  const privacyPolicyLink = useMemo(
    () => ({
      [LanguageCode.DE]: '/de/site/datenschutz',
      [LanguageCode.FR]: '/fr/site/protection-des-donnees',
    }),
    [],
  );

  const rootClassName = classNames(
    'Newsletter',
    className,
  );

  const text = useMemo(
    () => ({
      anErrorOccured: t('An error occured, please try again'),
      email: t('Email'),
      receiveNewsAndUpdates: t('Receive news and updates'),
      selectAtLeastOne: t('Select at least one newsletter to subscribe'),
      subscribe: t('Subscribe to newsletter'),
      tellUs: t('Tell us which emails you\'d like:'),
      thankYou: (email: string) => t('Thank you. We’ve sent the subscription confirmation link to <strong>{email}</strong>.', {
        email,
      }),
      weProcess: t('We process above information in order to subscribe you to the newsletter. More in our <a href="{link}" target="_blank">Privacy Policy</a>. You can unsubscribe at any time.', {
        link: privacyPolicyLink[language],
      }),
    }),
    [language, privacyPolicyLink, t],
  );

  const [alertSuccessSent, setAlertSuccessSent] = useState<{
    email: string | null;
    isVisible: boolean;
  }>({
    email: null,
    isVisible: false,
  });

  const onSubmit: SubmitHandler<any> = useCallback(
    async (data: NewsletterFormData) => {
      try {
        await submitNewsletter.mutateAsync({
          email: data.email,
          locale: language as LanguageCode,
          ...getSelectedCategories(data.newsletterCheckboxes),
        });

        reset({
          [FormField.email]: '',
        });

        setAlertSuccessSent({
          email: data[FormField.email as keyof typeof FormField],
          isVisible: true,
        });

        setTimeout(
          () => {
            setAlertSuccessSent({
              email: null,
              isVisible: false,
            });
          },
          ALERT_TIMEOUT,
        );
      } catch (e) {
        logger.error(e);
      }
    },
    [language, reset, submitNewsletter],
  );

  return (
    <form
      className={rootClassName}
      onSubmit={handleSubmit(onSubmit)}
    >
      <TextInput
        className="Newsletter-EmailInput"
        control={control}
        error={emailRule(errors[FormField.email])}
        isRequired={true}
        name={FormField.email}
        placeholder={text.email}
        validationPattern={EMAIL_PATTERN}
      />
      <div className="Newsletter-Text">
        {text.tellUs}
      </div>

      <NewsletterDropdown
        className="Newsletter-Dropdown"
        shouldReset={submitNewsletter.isSuccess}
        required={{
          clearErrors,
          setError,
        }}
        control={control}
      />

      {hasProcessInformation && (
        <div
          className="Newsletter-Text Newsletter-Text--ProcessInformation"
          dangerouslySetInnerHTML={{ __html: text.weProcess }}
        />
      )}

      <Button
        styleType={Button.StyleType.PRIMARY}
        isDisabled={submitNewsletter.isPending}
      >
        {text.subscribe}
      </Button>

      {submitNewsletter.isError && (
        <Alert
          className="Newsletter-Alert"
          isVisible={true}
          styleType={Alert.StyleType.WARNING}
        >
          {text.anErrorOccured}
        </Alert>
      )}

      {errors[getFormFieldCheckboxes(t).newsletterCheckboxes] && (
        <Alert
          className="Newsletter-Alert"
          isVisible={true}
          styleType={Alert.StyleType.WARNING}
        >
          {text.selectAtLeastOne}
        </Alert>
      )}

      {alertSuccessSent.isVisible && (
        <Alert
          className="Newsletter-Alert"
          isVisible={true}
          styleType={Alert.StyleType.SUCCESS}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: text.thankYou(alertSuccessSent.email || ''),
            }}
          />
        </Alert>
      )}
    </form>
  );
};

export {
  Newsletter,
};
