import 'core-js/stable/index.js';
import 'regenerator-runtime/runtime.js';
import React from 'react';
import { Cookies } from 'react-cookie';
import { hydrateRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { renderRoutes } from 'react-router-config';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import { datadogLogs } from '@datadog/browser-logs';
import Hotjar from '@hotjar/browser';
import * as Sentry from '@sentry/react';
import {
  HydrationBoundary,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RecoilRoot } from 'recoil';
import {
  NO_TRACKING_COOKIE_NAME,
  NO_TRACKING_COOKIE_VALUE,
} from 'dd-client/site/analytics/components/CookieOptOutPage/CookieOptOutPage';
import { ErrorBoundary } from 'dd-client/site/app/components/ErrorBoundary';
import { history } from 'dd-client/site/common/utils/history';
import { setLogger } from 'dd-client/site/common/utils/logger/logger';
import { consoleLogger } from 'dd-client/site/common/utils/logger/logger/console';
import { sentryLogger } from 'dd-client/site/common/utils/logger/logger/sentry';
import { getConfig } from 'dd-client/site/config/utils/config';
import { ROUTE_CONFIG } from 'dd-client/site/routes/components/Router';
import packageJson from '../../../../package.json';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: Infinity,
    },
  },
});

const dehydratedState = window.__REACT_QUERY_STATE__;
const env = getConfig('env');
const siteName = getConfig('siteName');
const logger = getConfig('logger');
const sentryKey = getConfig('sentry.publicKey');
const sentryAppId = getConfig('sentry.appId');
const sentrySampleRate = getConfig('sentry.sampleRate', 1);
const hotjarId = getConfig('hotjarId');
const hotjarVersion = 6;

const cookies = new Cookies();
const isTrackingActive = !cookies.get(NO_TRACKING_COOKIE_NAME)
  || cookies.get(NO_TRACKING_COOKIE_NAME) === NO_TRACKING_COOKIE_VALUE.DISABLED;

hotjarId && isTrackingActive && Hotjar.init(hotjarId, hotjarVersion);

switch (logger) {
  case 'sentry':
    Sentry.init({
      dsn: `https://${sentryKey}@o261477.ingest.sentry.io/${sentryAppId}`,
      environment: env,
      initialScope: {
        tags: {
          site: siteName,
        },
      },
      integrations: [new Sentry.BrowserTracing()],
      release: `v${packageJson.version}`,
      tracesSampleRate: sentrySampleRate,
    });

    setLogger(sentryLogger);
    break;

  case 'console':
    setLogger(consoleLogger);
    break;

  default:
    break;
}

datadogLogs.init({
  clientToken: getConfig('datadogLogs.clientToken'),
  env: env,
  forwardErrorsToLogs: true,
  service: getConfig('appName'),
  sessionSampleRate: getConfig('datadogLogs.sessionSampleRate'),
  site: 'datadoghq.com',
  version: getConfig('version'),
});

const rootElement = document.getElementById('root') as Element;

hydrateRoot(
  rootElement,
  <ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <HydrationBoundary state={dehydratedState}>
        <RecoilRoot>
          {/* @ts-ignore - bug in react helmet types: https://github.com/staylor/react-helmet-async/issues/188*/}
          <HelmetProvider>
            {/* @ts-ignore */}
            <Router history={history}>
              {renderRoutes(ROUTE_CONFIG)}
            </Router>
          </HelmetProvider>
        </RecoilRoot>
      </HydrationBoundary>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </ErrorBoundary>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
