import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Link } from 'dd-client/site/common/components/Link';
import { LanguageCode } from 'dd-client/site/i18n/utils/languageCode';
import BoxIcon from './assets/box.svg';
import CreditCardIcon from './assets/credit-card.svg';
import DeliveryIcon from './assets/delivery.svg';
import { Component, Props } from './types';
import './UniqueSellingPoints.scss';

const UniqueSellingPoints: Component = (
  { className } : Props,
): ReactElement => {
  const { i18n, t } = useTranslation();
  const language = i18n.language as LanguageCode;

  const text = useMemo(
    () => ({
      freeDelivery: t('Free delivery on all<br />Daydeal items'),
      payByCard: t('Pay by card, invoice or<br />monthly installments'),
      sameDayPickup: t('Same day pickup or<br />next business day delivery'),
    }),
    [t],
  );

  const rootClassName = classNames(
    'UniqueSellingPoints',
    'container',
    className,
  );

  const freeDeliveryLink = useMemo(
    () => ({
      [LanguageCode.DE]: 'https://www.brack.ch/versand',
      [LanguageCode.FR]: 'https://www.brack.ch/fr/livraison-et-frais-de-port',
    }),
    [],
  );

  const paymentLink = useMemo(
    () => ({
      [LanguageCode.DE]: 'https://www.brack.ch/zahlungsarten',
      [LanguageCode.FR]: 'https://www.brack.ch/fr/modes-de-paiement',
    }),
    [],
  );

  return (
    <div className={rootClassName}>
      <Link
        className="UniqueSellingPoints-Point"
        url={paymentLink[language]}
        target="_blank"
        rel="noreferrer"
      >
        <CreditCardIcon />
        <span
          dangerouslySetInnerHTML={{
            __html: text.payByCard,
          }}
        />
      </Link>

      <Link
        className="UniqueSellingPoints-Point"
        url={freeDeliveryLink[language]}
        target="_blank"
        rel="noreferrer"
      >
        <BoxIcon />
        <span
          dangerouslySetInnerHTML={{
            __html: text.freeDelivery,
          }}
        />
      </Link>

      <Link
        className="UniqueSellingPoints-Point"
        url={freeDeliveryLink[language]}
        target="_blank"
        rel="noreferrer"
      >
        <DeliveryIcon />
        <span
          dangerouslySetInnerHTML={{
            __html: text.sameDayPickup,
          }}
        />
      </Link>
    </div>
  );
};

export {
  UniqueSellingPoints,
};
