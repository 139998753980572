import { getConfig } from 'dd-client/site/config/utils/config';

const hasDealPageFullAnimated = getConfig('features.dealPageFullAnimated.enabled');
const hasNews = getConfig('features.news');
const hasNewsletter = getConfig('features.newsletter');
const hasPastDeals = getConfig('features.pastDeals');
const hasPromotions = getConfig('features.promotions');
const hasStaticPage = getConfig('features.staticPage');

export {
  hasDealPageFullAnimated,
  hasNews,
  hasNewsletter,
  hasPastDeals,
  hasPromotions,
  hasStaticPage,
};
