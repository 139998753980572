import React, { ReactElement, useCallback, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Alert } from 'dd-client/site/common/components/Alert';
import { Button } from 'dd-client/site/common/components/Button';
import { Checkbox } from 'dd-client/site/common/components/Form/Checkbox';
import { Error } from 'dd-client/site/common/components/Form/common/Error';
import { NewsletterDropdown } from 'dd-client/site/common/components/Form/NewsletterDropdown';
import { TextInput, Type } from 'dd-client/site/common/components/Form/TextInput';
import { logger } from 'dd-client/site/common/utils/logger/logger';
import { getSelectedCategories } from 'dd-client/site/common/utils/newsletter/newsletter';
import { EMAIL_PATTERN, emailRule, inputRule } from 'dd-client/site/common/utils/validations/validationRules';
import { LanguageCode } from 'dd-client/site/i18n/utils/languageCode';
import { useSubmitCompetition } from 'dd-client/site/static/hooks/useSubmitCompetition';
import { CompetitionFormData, Component, Props } from './types';
import './CompetitionForm.scss';

enum FormField {
  ANSWER = 'answer',
  FULL_NAME = 'fullName',
  EMAIL = 'email',
  ADDRESS = 'address',
  POSTAL_CODE_CITY = 'postalCodeCity',
  CONDITIONS_PARTICIPATION = 'conditionsParticipation',
  SUBSCRIBE_TO_NEWSLETTER = 'subscribeToNewsletter',
}
const COMPETITION_FORM_SENT_TIMEOUT = 3000;

const CompetitionForm: Component = ({
  className,
  competitionFormStructure,
}: Props): ReactElement => {
  const { t, i18n } = useTranslation();
  const language = i18n.language as LanguageCode;

  const {
    reset,
    handleSubmit,
    formState: { errors },
    register,
    control,
  } = useForm();
  const rootClassName = classNames(
    'CompetitionForm',
    className,
  );
  const submitCompetition = useSubmitCompetition();

  const text = useMemo(
    () => ({
      anErrorOccured: t('An error occured, please try again'),
      conditionsParticipation: t('Conditions of Participation'),
      email: t('E-Mail'),
      iAccept: t('I accept'),
      postalCodeAndCity: t('Postal code and city'),
      streetNumber: t('Street and number'),
      submit: t('Submit'),
      subscribeToNewsletter: t('Subscribe to Newsletter:'),
      thankYou: t('Thank you for the participation.'),
      yourAnswer: t('Your answer:'),
      yourName: t('Your name'),
    }),
    [t],
  );

  const onSubmit: SubmitHandler<any> = useCallback(
    async (data: CompetitionFormData)  => {
      try {
        await submitCompetition.mutateAsync({
          address: data.address,
          answer: data.answer,
          ...(data.subscribeToNewsletter && getSelectedCategories(data.newsletterCheckboxes)),
          conditionsParticipation: data.conditionsParticipation,
          email: data.email,
          fullName: data.fullName,
          locale: language,
          postalCodeCity: data.postalCodeCity,
          question: competitionFormStructure.title,
        });

        reset({
          [FormField.ANSWER]: '',
          [FormField.EMAIL]: '',
          [FormField.FULL_NAME]: '',
          [FormField.ADDRESS]: '',
          [FormField.POSTAL_CODE_CITY]: '',
          [FormField.CONDITIONS_PARTICIPATION]: false,
          [FormField.SUBSCRIBE_TO_NEWSLETTER]: false,
        });

        setTimeout(
          () => submitCompetition.reset(),
          COMPETITION_FORM_SENT_TIMEOUT,
        );
      } catch (e) {
        logger.error(e);
      }
    },
    [competitionFormStructure.title, language, reset, submitCompetition],
  );

  const conditionsLink = useMemo(
    () => ({
      [LanguageCode.DE]: '/de/site/teilnahmebedingungen',
      [LanguageCode.FR]: '/fr/site/conditions-de-participation',
    }),
    [],
  );

  return (
    <div className={rootClassName}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h4>
          {competitionFormStructure.title}
        </h4>

        <div className="CompetitionForm-Text">
          {text.yourAnswer}
        </div>

        <div className="CompetitionForm-RadioWrapper">
          {competitionFormStructure.answers.map(
            answer => (
              <label
                className="CompetitionForm-RadioLabel"
                key={answer.label}
              >
                <input
                  className="CompetitionForm-Radio"
                  type="radio"
                  value={answer.value}
                  {...register(FormField.ANSWER, { required: true })}
                />
                {answer.label}
              </label>
            ),
          )}

          {errors[FormField.ANSWER] && (
            <Error>
              {inputRule(errors[FormField.ANSWER])}
            </Error>
          )}
        </div>

        <TextInput
          control={control}
          error={emailRule(errors[FormField.EMAIL])}
          isRequired={true}
          placeholder={`${text.email}*`}
          name={FormField.EMAIL}
          validationPattern={EMAIL_PATTERN}
          type={Type.EMAIL}
        />

        <TextInput
          control={control}
          placeholder={text.yourName}
          name={FormField.FULL_NAME}
        />

        <TextInput
          control={control}
          placeholder={text.streetNumber}
          name={FormField.ADDRESS}
        />

        <TextInput
          control={control}
          placeholder={text.postalCodeAndCity}
          name={FormField.POSTAL_CODE_CITY}
        />

        <div className="CompetitionForm-CheckboxAcceptWrapper">
          <Checkbox
            control={control}
            label={(
              <>
                {text.iAccept}&nbsp;
                <a
                  href={conditionsLink[language]}
                  target="_blank"
                  rel="noreferrer"
                >
                  <strong>{text.conditionsParticipation}</strong>
                </a>
              </>
            )}
            isDefaultChecked={false}
            error={inputRule(errors[FormField.CONDITIONS_PARTICIPATION])}
            isRequired={true}
            name={FormField.CONDITIONS_PARTICIPATION}
          />
        </div>

        <div className="CompetitionForm-CheckboxSubscribeWrapper">
          <Checkbox
            control={control}
            label={text.subscribeToNewsletter}
            isDefaultChecked={false}
            name={FormField.SUBSCRIBE_TO_NEWSLETTER}
          />
        </div>

        <NewsletterDropdown
          className="CompetitionForm-NewsletterDropdown"
          control={control}
          shouldReset={submitCompetition.isSuccess}
        />

        <Button isDisabled={submitCompetition.isPending}>
          {text.submit}
        </Button>

        {submitCompetition.isSuccess && (
          <Alert
            className="CompetitionForm-Alert"
            isVisible={true}
            styleType={Alert.StyleType.SUCCESS}
          >
            {text.thankYou}
          </Alert>
        )}

        {submitCompetition.isError && (
          <Alert
            className="CompetitionForm-Alert"
            isVisible={true}
            styleType={Alert.StyleType.WARNING}
          >
            {text.anErrorOccured}
          </Alert>
        )}
      </form>
    </div>
  );
};

export {
  CompetitionForm,
};
