import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  ANALYTICS_ACTION,
  ANALYTICS_BOOLEAN,
  ANALYTICS_CATEGORIES,
  ANALYTICS_PAGE_TYPE,
  ANALYTICS_PLATFORM, ANALYTICS_SERVICE,
} from 'dd-client/site/analytics/utils/analytics';
import { usePageMatch } from 'dd-client/site/common/hooks/usePageMatch';
import { logger } from 'dd-client/site/common/utils/logger/logger';
import { replaceParamsInPath } from 'dd-client/site/common/utils/replaceParamsInPath/replaceParamsInPath';
import { Deal } from 'dd-client/site/deal/hooks/api/types';
import { useCurrentDeal } from 'dd-client/site/deal/hooks/useCurrentDeal';
import { LanguageCode } from 'dd-client/site/i18n/utils/languageCode';
import { useStaticPage } from 'dd-client/site/static/hooks/useStaticPage';
import { PageViewPayload, PageViewProduct } from '../types';

const PAGE_VIEW_DELAY = 300;
const ANALYTICS_META_TITLE = 'title-de';

const getDealData = (deal: Deal | undefined): Array<PageViewProduct> => {
  if (!deal || !deal.sku) {
    return [];
  }

  return [{
    action: ANALYTICS_ACTION.dealDetails,
    categories: ANALYTICS_CATEGORIES,
    id: `${deal.sku}`,
    price: `${deal.specialPrice}`,
    purchasable: deal.availability.buyable ? ANALYTICS_BOOLEAN.true : ANALYTICS_BOOLEAN.false,
    service: ANALYTICS_SERVICE,
    stock: deal.availability.buyable ? '1' : ANALYTICS_BOOLEAN.false,
  }];
};

const usePageViewAnalytics = (): void => {
  const { i18n: { language } } = useTranslation();
  const { isDealPage, isCategoryPage, isHomePage, isStaticPage, staticPageParam } = usePageMatch();
  const currentDeal = useCurrentDeal();
  const currentDealData = currentDeal?.data;
  const staticPage = useStaticPage(staticPageParam);
  const staticPageData = staticPage?.data;
  const location = useLocation();

  useEffect(
    () => {
      setTimeout(() => {
        if (!window?.utagQ?.view) {
          return;
        }

        const prod = [];
        if ((isDealPage || isCategoryPage || isHomePage)) {
          if (!currentDealData && currentDeal?.status !== 'error') { //prevent duplicated page view trigger before data is loaded
            return;
          }

          prod.push(...getDealData(currentDealData));
        } else if (isStaticPage) {
          if (!staticPageData && staticPage.status !== 'error') { //prevent duplicated page view trigger before data is loaded
            return;
          }
        }

        const analyticsUrl = replaceParamsInPath(
          location.pathname,
          {
            category: currentDealData?.analytics.categorySlug,
            deal: currentDealData?.analytics.dealSlug,
            lang: LanguageCode.DE,
            page: staticPageData?.analytics.slug,
          },
        );

        const titleDE = (document.querySelector(`meta[name="${ANALYTICS_META_TITLE}"]`) as HTMLMetaElement)?.content;

        const payload: PageViewPayload = {
          page: {
            language: language as LanguageCode,
            title: titleDE,
            type: ANALYTICS_PAGE_TYPE,
          },
          platform: ANALYTICS_PLATFORM,
          ...(prod.length) && { prod },
          url_full: window.location.href,
          url_pathGerman: analyticsUrl,
        };

        try {
          window.utagQ.view(payload);
        } catch (e) {
          logger.error(e);
        }
      }, PAGE_VIEW_DELAY);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentDeal?.status,
      currentDealData?.id,
      isCategoryPage,
      isDealPage,
      isHomePage,
      isStaticPage,
      language,
      location.pathname,
      staticPage.status,
      staticPageData,
    ],
  );
};

export {
  ANALYTICS_META_TITLE,
  usePageViewAnalytics,
};
