import { DEFAULT_AVAILABILITY } from 'dd-client/site/common/hooks/useSseUpdates';
import { Deal as DealResponse } from './connector.types';
import { Deal, KeyFeatures } from './types';

const mapDealToState = (
  deal: DealResponse,
): Deal => ({
  accessories: Array.isArray(deal.accessories)
    ? deal.accessories.map(
      accessoryGroup => ({
        ...accessoryGroup,
        products: accessoryGroup.products.map(
          ({ new: isNew, sale: isSale, ...rest }) => ({
            ...rest,
            isNew,
            isSale,
          }),
        ),
      }),
    )
    : [],
  analytics: {
    ...deal.analytics,
    metaTitle: deal.analytics.metaTitle || '',
  },
  apps: deal.apps,
  availability: DEFAULT_AVAILABILITY,
  calculatedDiscount: deal.calculatedDiscount,
  category: {
    id: deal.category.id,
    name: deal.category.name,
    slug: deal.category.slug,
  },
  comparison: deal.comparison,
  deliveryInformation: deal.deliveryInformation,
  description: deal.description,
  detail: deal.detail,
  endDate: deal.endDate.replace(' ', 'T'),
  id: deal.id,
  keyFeatures: Array.isArray(deal.keyFeatures)
    ? deal.keyFeatures.filter(({ keyFeature }) => keyFeature !== null) as KeyFeatures
    : [],
  links: Array.isArray(deal.links) ? deal.links : [],
  mainImage: deal.mainImage,
  media: Array.isArray(deal.media) ? deal.media : [],
  meta: deal.meta,
  navisionId: deal.navisionId,
  og: deal.og,
  originalPrice: deal.originalPrice,
  pdpUrl: deal.pdpUrl,
  recommendations: Array.isArray(deal.recommendations)
    ? deal.recommendations.map(
      ({ new: isNew, sale: isSale, ...rest }) => ({
        ...rest,
        isNew,
        isSale,
      }),
    )
    : [],
  scopeOfDelivery: deal.scopeOfDelivery,
  sku: deal.sku,
  slug: deal.slug,
  slugs: deal.slugs,
  specialPrice: deal.specialPrice,
  specification: deal.specification,
  startDate: deal.startDate.replace(' ', 'T'),
  stickers: Array.isArray(deal.stickers) ? deal.stickers : [],
  subtitle: deal.subtitle,
  themeId: deal.themeId,
  title: deal.title,
  variants: Array.isArray(deal.variants)
    ? deal.variants.map(
      variantGroup => ({
        items: variantGroup.items.map(
          variant => ({
            availability: DEFAULT_AVAILABILITY,
            calculatedDiscount: variant.calculatedDiscount,
            id: variant.id,
            image: variant.image,
            name: variant.name,
            navisionId: variant.navisionId,
            originalPrice: variant.originalPrice,
            specialPrice: variant.specialPrice,
          }),
        ),
        name: variantGroup.name,
      }),
    )
    : [],
  video: deal.video,
});

export {
  mapDealToState,
};
