import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import CloseIcon from 'dd-client/site/common/assets/icons/close.svg';
import { ButtonIcon } from 'dd-client/site/common/components/ButtonIcon';
import { Newsletter } from 'dd-client/site/common/components/Form/Newsletter';
import { Component, Props } from './types';
import './SoldOutBox.scss';

const SoldOutBox: Component = ({
  className,
  slug,
}: Props): ReactElement | null => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  const text = useMemo(
    () => ({
      missedDeal: t('Missed a deal? Subscribe to the DayDeal newsletter now and never miss an offer again!'),
      soldOut: t('Sold out!'),
    }),
    [t],
  );

  const rootClassName = classNames(
    'SoldOutBox',
    className,
  );

  const handleClose = useCallback(
    () => setIsVisible(false),
    [],
  );

  useEffect(
    () => {
      setIsVisible(true);
    },
    [slug],
  );

  if (!isVisible) {
    return null;
  }

  return (
    <div
      className={rootClassName}
      data-testid="sold-out-box"
    >
      <ButtonIcon
        className="SoldOutBox-CloseButton"
        icon={<CloseIcon />}
        onClick={handleClose}
        size={ButtonIcon.Size.SMALL}
        styleType={ButtonIcon.StyleType.GHOST}
      />

      <div className="SoldOutBox-LabelWrapper">
        <span className="SoldOutBox-Label">
          {text.soldOut}
        </span>
      </div>

      <p className="SoldOutBox-Text">
        {text.missedDeal}
      </p>

      <Newsletter
        className="SoldOutBox-Newsletter"
        key={slug}
        hasProcessInformation={false}
      />
    </div>
  );
};

export {
  SoldOutBox,
};
