export default Object.freeze({
  basicAuth: {
    enabled: false,
  },
  datadogLogs: {
    clientToken: 'pub9af0d343eb7672723b4159ad226556fe',
    sessionSampleRate: 100,
  },
  env: 'prod',
  features: {
    dealPageFullAnimated: {
      enabled: false,
      endTime: new Date('2023-11-23T17:45:00'),
      startTime: new Date('2023-11-23T09:00:00'),
    },
    news: true,
    newsletter: true,
    pastDeals: true,
    promotions: true,
    staticPage: true,
  },
  hotjarId: '3516267',
  logger: 'sentry',
  metrics: {
    enabled: true,
  },
  reCaptcha: {
    siteKey: '6Ld-yY8jAAAAAOemHpt6Xxk-9nX_wCoaMxDMrGew',
  },
  sentry: {
    appId: '6047754',
    publicKey: 'fb80e89e7b1742ef9bae5af0d07fd640',
    sampleRate: 0,
  },
  siteName: 'daydeal',
  tenant: 'Daydeal',
  url: {
    analytics: 'https://i.daydeal.ch/utag/competec/dealsites/prod/u.js',
    api: {
      broadcast: 'https://broadcast.daydeal.ch',
      comment: 'https://comment.daydeal.ch/api/ui',
      deal: 'https://deal.daydeal.ch/api/ui',
      static: 'https://static.daydeal.ch/api/ui',
    },
    apiSsr: {
      broadcast: 'https://broadcast.int.prod.dd.competec.ninja',
      comment: 'https://comment.int.prod.dd.competec.ninja/api/ui',
      deal: 'https://deal.int.prod.dd.competec.ninja/api/ui',
      static: 'https://static.int.prod.dd.competec.ninja/api/ui',
    },
    cdn: 'https://images.daydeal.ch/files',
    root: 'https://daydeal.ch',
  },
});
